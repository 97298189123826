var Drupal = Drupal || {};
var settings = Drupal.settings || {};
(function($) {
  Drupal.behaviors.vkontakteLogin = {
    attached: false,
    nodes: {},
    vk_initialized: false,
    code_param: null,
    _getDOMNodes: function() {
      this.nodes.container = $('.js-vk-social-login');
      this.nodes.socialContainer = $('.js-social-login-container');
      this.nodes.socialInfoContainer = $('.js-social-info-vk');
      this.nodes.vkLoginButton = this.nodes.socialInfoContainer.length ? this.nodes.socialInfoContainer.find('.js-vkontakte-login') : this.nodes.container.find('.js-vkontakte-login');
      this.nodes.socialLoginTermsAgreement = this.nodes.socialInfoContainer.length ? this.nodes.socialInfoContainer.find('input[name=SOCIAL_LOGIN_TERMS_AGREEMENT]') : this.nodes.container.find('input[name=SOCIAL_LOGIN_TERMS_AGREEMENT]');
    },
    showVkContainer: function() {
      this.nodes.container.removeClass('hidden');
      this.nodes.socialContainer.removeClass('hidden');
    },
    _getAppId: function() {
      if (settings.vk_login_app_id && settings.vk_login_app_id !== '') {
        return settings.vk_login_app_id;
      }
    },
    attachClicks: function() {
      if (this.nodes && this.nodes.vkLoginButton) {
        var self = this;
        this.nodes.vkLoginButton.on('click', function() {
          return self._login(self);
        });
      }
    },
    initVK: function() {
      var appId = this._getAppId();
      if (!appId) {
        return;
      }
      window.VK.init({
        apiId: appId,
      });
      this.vk_initialized = true;
    },
    init: function() {
      this._getDOMNodes();
      this.showVkContainer();
      this.attachClicks();
    },
    _login: function(context) {
      var current_path = window.location.href;
      var redirect_url = 'https://' + window.location.host + '/account/vkontakte_signin.tmpl';
      var redirect_to = $(this).data('redirect');
      var params = [];
      if (typeof redirect_to !== 'undefined') {
        params.push('RETURN_URL=' + encodeURIComponent(redirect_to));
      } else if (current_path.match('/checkout(?!/confirm)/')) {
        params.push('RETURN_URL=' + encodeURIComponent('/checkout/index.tmpl'));
      } else if (current_path.search('RETURN_URL') > -1) {
        var url_to_parse = new URL(current_path);
        redirect_to = url_to_parse.searchParams.get('RETURN_URL');
        params.push('RETURN_URL=' + encodeURIComponent(redirect_to));
      }
      if (context.nodes.socialLoginTermsAgreement.length) {
        var social_agreement_value = Number(context.nodes.socialLoginTermsAgreement.val()) || '';
        params.push('SOCIAL_LOGIN_TERMS_AGREEMENT=' + social_agreement_value);
      }
      if (params.length) {
        var params_string = params.join('&');
        /* We should use double encoding because redirect_url is a part of URL which is also a part of URL.
 *         With single encode parts of redirect_url will be decoded as parts of whole URL. */
        redirect_url += '?' + encodeURIComponent(params_string);
      }
      if (!window.VK._domain.main.match('/$')) {
        window.VK._domain.main = window.VK._domain.main + '/';
      }
      var url = window.VK._domain.main
        + window.VK._path.login
        + '?client_id='
        + window.VK._apiId
        + '&display=page&redirect_uri='
        + redirect_url
        + '&response_type=code&scope=email,offline';
      top.location.href = url;
    },
    /* Fallback function if URLSearchParams is not available
 *      * Returning get parameter value from the current URL
 *           */
    getUrlParameter: function(name) {
      if (typeof URLSearchParams === 'function') {
        var query_string = new URLSearchParams(location.search);
        var code_param = query_string.get('code');
        return code_param;
      } else {
        name = name.replace(/\[/, '\\[').replace(/\]/, '\\]');
        var regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
        var results = regex.exec(location.search);
        return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
      }
    },
    attach: function(context) {
      if (!!this.attached || typeof VK !== 'undefined') {
        return;
      }
      if (document.getElementById('vkontakte-jssdki')) {
        return;
      }
      window.vkAsyncInit = function() {
        Drupal.behaviors.vkontakteLogin.initVK();
      };
      this.init(context);
      this.attached = true;
    }
  };
})(jQuery);
